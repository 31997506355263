import auth0 from "auth0-js";
import { navigate } from "gatsby";
import jwtDecode from "jwt-decode";
import moment from "moment";

import scopes from "~/utils/auth/scopes";

type DecodedToken = {
  iss: string;
  exp: number;
  azp: string;
  aud: string;
  gty: string;
  iat: number;
  scope: string;
  sub: string;
  "https://api.saludando.cl/roles": string[];
};

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
};

const windowGlobal = typeof window !== "undefined" && window;

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: "saludando-mp.us.auth0.com",
    clientID: "354uzMi8uH52e1hff61IXr2fn4vth2o6",
    redirectUri: windowGlobal ? `${windowGlobal.location.origin}/callback` : null,
    audience: `https://api.saludando.cl`,
    responseType: "token id_token",
    scope: scopes,
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.silentAuth = this.silentAuth.bind(this);
    this.getUser = this.getUser.bind(this);
  }

  login() {
    if (windowGlobal) {
      this.auth0.authorize();
    }
  }

  logout(
    callback = () => {
      this.auth0.logout({ returnTo: window.location.origin });
    },
  ) {
    if (windowGlobal) {
      windowGlobal.localStorage.removeItem("user");
      windowGlobal.localStorage.removeItem("token");
      windowGlobal.localStorage.removeItem("isLoggedIn");
      windowGlobal.localStorage.removeItem("refresh_token");

      callback();
    }
  }

  getAccessToken() {
    if (windowGlobal && localStorage.hasOwnProperty("token")) {
      return localStorage.getItem("token") || "";
    }
    return "";
  }

  getRefreshToken() {
    if (windowGlobal && localStorage.hasOwnProperty("refresh_token")) {
      return localStorage.getItem("refresh_token") || "";
    }
    return "";
  }

  hasRole(roleName: string) {
    try {
      const user = this.getUser();
      return user ? user["https://api.saludando.cl/roles"].includes(roleName) : false;
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  handleAuthentication() {
    if (windowGlobal) {
      this.auth0.parseHash((err, authResult) => {
        this.setSession()(err, authResult);
      });
    } else {
      console.log("Could not find window in handle Authentication");
    }
  }

  setSession =
    (cb = () => {}) =>
    (err, authResult) => {
      if (windowGlobal) {
        if (err) {
          windowGlobal.location.href = "/";
          cb();
          return;
        }

        if (authResult && authResult.accessToken && authResult.idToken) {
          windowGlobal.localStorage.setItem("user", JSON.stringify(authResult.idTokenPayload));

          // not allowed
          if (!this.isSuperadmin()) {
            this.logout(() => {
              this.auth0.logout({
                returnTo: window.location.origin + "?error=NotAllowed",
              });
            });
            return;
          }

          const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
          tokens.accessToken = authResult.accessToken;
          tokens.idToken = authResult.idToken;
          localStorage.setItem("token", tokens.accessToken);
          tokens.expiresAt = expiresAt;
          navigate("/dashboard/");

          cb();
        }
      }
    };

  setUser(user) {
    if (windowGlobal) {
      windowGlobal.localStorage.setItem("user", JSON.stringify(user));
    }
  }

  getUser() {
    if (windowGlobal) {
      try {
        if (windowGlobal.localStorage.getItem("user")) {
          return JSON.parse(windowGlobal.localStorage.getItem("user"));
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  isLoggedIn() {
    const user = this.getUser();
    return !!user;
  }

  isSuperadmin(): boolean {
    return this.hasRole("superadmin");
  }

  isSuperadmin1(): boolean {
    return this.hasRole("superadmin1");
  }

  isCouponAdmin(): boolean {
    return this.hasRole("coupon_admin");
  }

  isNursePaymentAdmin(): boolean {
    return this.hasRole("nurse_payment_admin");
  }

  isNursePaymentEdit(): boolean {
    return this.hasRole("nurse_payment_edit");
  }

  silentAuth = (callback) => {
    if (!this.isAuthenticated()) return callback();
    this.auth0.checkSession({}, this.setSession(callback));
  };

  getUserName() {
    if (this.getUser()) {
      return this.getUser().name;
    }
  }

  tokenIsExpired = (): boolean => {
    const token = this.getAccessToken();
    if (!token) {
      return true;
    }
    const decoded: DecodedToken = jwtDecode(token);
    if (decoded.exp) {
      const expMoment = moment(decoded.exp * 1000);
      const hourAgo = moment().subtract(1, "hour");
      return expMoment.isBefore(hourAgo);
    }
    return true;
  };
}
