import React from "react";

import { AxiosError } from "axios";
import { isEmpty } from "lodash";

import TubeLoader from "~/components/Loaders/TubeLoader";

interface Props {
  loading: boolean;
  error?: AxiosError | {};
}

const LoadingError = ({ loading, error }: Props): JSX.Element => {
  return (
    <>
      {loading && (
        <div className="p-32 w-full flex items-center flex-col">
          <h1>Cargando...</h1>
          <TubeLoader style={{ marginTop: 40 }} />
        </div>
      )}
      {error && !isEmpty(error) && (
        <div className="p-32 w-full flex items-center flex-col">
          <h3 className="text-center w-full text-red-500">
            Lo sentimos, ha ocurrido un error, por favor intenta nuevamente más tarde. Si el error persiste, por favor
            comunícate con el equipo de desarrollo.
            <br />
            {error.response?.status}: {JSON.stringify(error.response?.data)}
          </h3>
        </div>
      )}
    </>
  );
};

export default LoadingError;
