import React, { useEffect, useState } from "react";

import { Button, createTheme, ThemeProvider } from "@material-ui/core";
import { globalHistory } from "@reach/router";
import { navigate } from "gatsby";
import { FaBookMedical, FaClinicMedical, FaUser, FaWpforms } from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import { IoCloudOffline } from "react-icons/io5";
import {
  MdDashboard,
  MdDataUsage,
  MdDiscount,
  MdMap,
  MdPayments,
  MdShoppingCart,
  MdSupervisorAccount,
} from "react-icons/md";
import { TiArrowRepeat } from "react-icons/ti";

import clsx from "clsx";
import LoadingError from "~/components/Loaders/LoadingError";
import Navbar from "~/components/nav/Navbar";
import FilterStore from "~/components/Stores/FilterStore";
import authenticationService from "~/utils/api/v1/authenticationService";
import Auth from "~/utils/auth/auth";
import { isProd } from "~/utils/environment";

const auth = new Auth();

const logoAsset = require(`~/assets/branding/examedi-logo-2023.png`).default;
export const theme = createTheme({
  palette: {
    primary: {
      light: "#4eb9ec",
      main: "#039Be5",
      dark: "#027cb7",
      contrastText: "#001f2d",
      warning: "#ffc302",
      success: "#00ff00",
      error: "#FF0505",
    },
    secondary: {
      main: "#FF6D70",
    },
  },
});

const navs = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <MdDashboard className="text-[#039BE5]" />,
  },
  {
    url: `https://${isProd ? "" : "staging-"}ops.examedi.com/telehealths/`,
    name: "Telemedicina",
    icon: <MdDashboard className="text-[#039BE5]" />,
    external: true,
    requiredRole: "employee",
  },
  {
    path: "/users",
    name: "Usuarios",
    icon: <FaUser className="text-[#039BE5]" />,
  },
  {
    path: "/patients",
    name: "Pacientes",
    icon: <FaBookMedical className="text-[#039BE5]" />,
  },
  {
    path: "/nurse-payments",
    name: "Pagos HT",
    icon: <MdPayments className="text-[#039BE5]" />,
    requiredRole: "nurse_payment_admin",
  },
  {
    path: "/capacity",
    name: "Capacity",
    icon: <MdDataUsage className="text-[#039BE5]" />,
  },
  {
    path: "/health-team",
    name: "Health Team",
    icon: <MdSupervisorAccount className="text-[#039BE5]" />,
  },
  {
    path: "/sectors",
    name: "Sectores",
    icon: <MdMap className="text-[#039BE5]" />,
  },
  {
    path: "/laboratory",
    name: "Laboratorios",
    icon: <FaClinicMedical className="text-[#039BE5]" />,
  },
  {
    path: "/offline-appointment/create",
    name: "Operativos CL",
    icon: <IoCloudOffline className="text-[#039BE5]" />,
  },
  {
    path: "/offline-appointment-mx/create",
    name: "Offline MX",
    icon: <IoCloudOffline className="text-[#039BE5]" />,
  },
  {
    path: "/offline-appointment/assisted-schedule",
    name: "Agendamiento asistido",
    icon: <IoCloudOffline className="text-[#039BE5]" />,
  },
  {
    path: "/checkout-builder/",
    name: "Carrito Maker",
    icon: <MdShoppingCart className="text-[#039BE5]" />,
  },
  {
    path: "/medical-orders",
    name: "Órdenes médicas",
    icon: <FaWpforms className="text-[#039BE5]" />,
  },
  {
    path: "/subscriptions",
    name: "Suscripciones",
    icon: <TiArrowRepeat className="text-[#039BE5]" />,
  },
  {
    path: "/coupons",
    name: "Cupones",
    icon: <MdDiscount className="text-[#039BE5]" />,
  },
  {
    path: "/new-packs/",
    name: "Packs Nuevos",
    icon: <FiPackage className="text-[#039BE5]" />,
  },
  {
    path: "/dashboard/totalpack",
    name: "Total Pack",
    icon: <MdDashboard className="text-[#039BE5]" />,
  },
  {
    path: "/pending",
    name: "Resultados por Revisar",
    icon: <MdMap className="text-[#039BE5]" />,
  },
  {
    path: "/refunds",
    name: "Reembolsos Cancelaciones",
    icon: <FaWpforms className="text-[#039BE5]" />,
  },
];

const MainLayout = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showLayout, setShowLayout] = useState(window.location.pathname !== "/");
  const [active, setActive] = useState<string>("Dashboard");

  const fetchRefreshToken = async () => {
    setLoading(true);
    try {
      await authenticationService.refreshAccessToken();
    } catch (err) {
      console.log(err);
      auth.logout(() => {});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return globalHistory.listen((routeChange) => {
      if (routeChange.action === "PUSH") {
        setShowLayout(routeChange.location.pathname !== "/");
      }
    });
  }, []);

  useEffect(() => {
    const accessToken = auth.getAccessToken();
    const refreshToken = auth.getRefreshToken();
    if ([accessToken, refreshToken].includes("")) {
      setLoading(false);
      return;
    }
    const lastExecution = localStorage.getItem("lastExecutionTimestamp");
    const currentDate = new Date().getTime();
    const twentyFourHours = 24 * 60 * 60 * 1000;
    const hasToRefreshToken = !lastExecution || currentDate - lastExecution >= twentyFourHours;
    if (hasToRefreshToken) {
      fetchRefreshToken();
      localStorage.setItem("lastExecutionTimestamp", currentDate);
    }
    setLoading(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.1/dist/leaflet.css"
        integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14="
        crossOrigin=""
      />
      <script
        src="https://unpkg.com/leaflet@1.9.1/dist/leaflet.js"
        integrity="sha256-NDI0K41gVbWqfkkaHj15IzU7PtMoelkzyKp8TOaFQ3s="
        crossOrigin=""
      />
      <FilterStore>
        <>
          <div className="text-left min-h-screen grid grid-cols-12 w-full">
            {auth.isLoggedIn() && (
              <div className="fixed bg-gray-100 col-span-1 border-r-2 border-gray-100 overflow-y-scroll overflow-x-clip h-screen">
                <div
                  className="flex items-center justify-center"
                >
                  <img className="w-fit h-6 object-contain mt-4" src={logoAsset} />
                </div>
                <div className="mt-2">
                  {navs.map((item, i) => {
                    const { requiredRole } = item;
                    if (requiredRole && !auth.hasRole(requiredRole)) {
                      return null;
                    }
                    return (
                      <button
                        key={i}
                        className={clsx(
                          "w-full flex items-center p-4 hover:!bg-gray-100 hover:opacity-30 hover:scale-110 transition-all duration-300 ease-in-out no-underline",
                          active === item.name && "bg-gray-200",
                          active !== item.name && "bg-white",
                        )}
                        onClick={() => {
                          if (item.external) {
                            window.location.href = item.url;
                          } else {
                            navigate(item.path);
                            setActive(item.name);
                          }
                        }}
                      >
                        {item.icon} <h6 className="ml-5 mb-0 font-medium text-left">{item.name}</h6>
                      </button>
                    );
                  })}
                  <button className="flex items-center p-4 pl-5 hover:bg-gray-100">
                    <Button onClick={() => auth.logout(async () => navigate("/"))}>Cerrar Sesión</Button>
                  </button>
                </div>
              </div>
            )}
            <div className={clsx("w-full bg-gray-100 col-span-full flex flex-col col-start-1", showLayout && auth.isLoggedIn() && "col-start-3 col-span-10 px-20")}>
              {showLayout && auth.isLoggedIn() && <Navbar />}
              {loading && (
                <LoadingError
                  loading={loading}
                  error={false}
                />
              )}
              {!loading && <div className={clsx(showLayout && auth.isLoggedIn() && "mt-16")}>{props.children}</div>}
            </div>
          </div>
        </>
      </FilterStore>
    </ThemeProvider>
  );
};

export default MainLayout;
