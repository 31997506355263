import React from "react";

import tubeLoader from "~/assets/animations/loading_tube.gif";

interface Props {
  style?: Object;
}

const TubeLoader = (props: Props) => {
  return (
    <img
      src={tubeLoader}
      className="h-48 w-48 object-contain"
      style={props.style}
    />
  );
};

export default TubeLoader;
