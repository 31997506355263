import React, { useEffect, useState } from "react";

import { globalHistory } from "@reach/router";
import clsx from "clsx";
import { navigate } from "gatsby";
import { stringify } from "query-string";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { encodeQueryParams, StringParam } from "serialize-query-params";

import SearchBar from "~/components/inputs/SearchBar";
import Auth from "~/utils/auth/auth";

const auth = new Auth();

const PrimaryNavbar = () => {
  const [search, setSearch] = useState<string>("");
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(auth.isLoggedIn());

  /* https://stackoverflow.com/questions/61274365/allow-component-to-detect-route-change-in-gatsby
  use a loggedIn state to update the navbar when needed. On each route change, it will check
  the auth isLoggedIn state and refresh the state of the navbar. */

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setLoggedIn(auth.isLoggedIn());
      }
    });
  });

  const goToReports = (): void => {
    navigate("/reports");
  };

  const renderMobileNav = () => (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-white overflow-scroll pb-24">
      <div className="flex items-center justify-between p-4 bg-[#020b34] border-b border-[#020b34]">
        <AiOutlineCloseSquare
          className="text-white"
          fontSize={36}
          onClick={() => {
            setShowMobileNav(!showMobileNav);
          }}
        />
      </div>
      <div className="p-8 w-full">
        {loggedIn ? <></> : <></>}
        <div
          className="flex items-center w-full py-4 text-[#020b34] font-light hover:bg-whitesmoke cursor-pointer"
          onClick={goToReports}
        >
          <div className="w-0 h-0 border-t-10 border-b-10 border-l-10 border-transparent border-l-[#f15f5e] mr-8"></div>
          Cerrar sesión
        </div>
      </div>
    </div>
  );

  const renderSearchBar = (path: string, queryPath: string) => (
    <SearchBar
      value={search}
      onChange={(newValue) => {
        setSearch(newValue);
      }}
      placeholder="Email, Nombre o RUT"
      onRequestSearch={() => {
        const trimmedSearch = search.trim();
        const encodedQuery = encodeQueryParams({ search: StringParam }, { search: trimmedSearch });
        navigate(`${queryPath}?${stringify(encodedQuery)}`);
        setSearch("");
      }}
    />
  );

  const renderDesktopNav = () => {
    const pathname = window.location.pathname;
    return (
      <div className="flex flex-row items-center">
        {pathname.startsWith("/users") && renderSearchBar("/users", "/users")}
        {pathname.startsWith("/patients") && renderSearchBar("/patients", "/patients")}
        {!(pathname.startsWith("/users") || pathname.startsWith("/patients")) &&
          renderSearchBar("/dashboard", "/dashboard")}
      </div>
    );
  };

  return (
    <div
      className={clsx(
        "h-15 py-2 flex w-full items-center bg-white z-40 fixed",
        !auth.isLoggedIn() && "hidden",
      )}
    >
      <div className="flex items-center justify-between w-full">
        <span>
          <div className="relative md:hidden">
            {showMobileNav && renderMobileNav()}
            <GiHamburgerMenu
              onClick={() => {
                setShowMobileNav(!showMobileNav);
              }}
            />
          </div>
          <div className="hidden md:block">{renderDesktopNav()}</div>
        </span>
      </div>
    </div>
  );
};

export default PrimaryNavbar;
