import React from "react";

import clsx from "clsx";

import MagnifyingGlassIcon from "~/components/icons/MagnifyingGlassIcon";

interface SearchBarProps {
  value: string;
  onChange: (newValue: string) => void;
  onRequestSearch: () => void;
  placeholder?: string;
  className?: string;
}

function SearchBar({ value, onChange, onRequestSearch, placeholder = "Buscar...", className }: SearchBarProps) {
  return (
    <div className={clsx("flex items-center border border-gray-300 rounded-md px-3 py-2 w-full max-w-md", className)}>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="flex-1 p-1 outline-none w-96"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onRequestSearch();
          }
        }}
      />
      <MagnifyingGlassIcon />
    </div>
  );
}

export default SearchBar;
